/*
 * @Author: zhongxue
 * @Date: 2020-09-06 21:02:00
 * @LastEditors  : zhongxue
 * @LastEditTime : 2020-09-07 10:00:00
 */

import React from 'react';
import RootRouter from './router';
import CommonUI from './utils-tools/commonUI.config.js';
import { IS_SUPPORT_SEO } from './services/seo.js';
import { Helmet } from 'react-helmet';
import { message } from 'antd';
import './statics/css/change/theme.less';
import './statics/css/custom/common.css';
import './statics/css/custom/reset.css';
import './statics/css/index.less';
import { Inspector } from 'react-dev-inspector';
const InspectorWrapper = process.env.NODE_ENV === 'development' ? Inspector : React.Fragment;

// React.Component = React.PureComponent;

React.CommonUI = CommonUI;
message.config({
  maxCount: 1
});

// class SEOapp extends React.PureComponent {
//   state = {
//     keywords: '',
//     description: '',
//     title: '',
//     configPath: {
//       SEO_SET_INDEX: ['/', '/home', '/home/index'],
//       SEO_SET_COURSE: ['/course', '/course/list'],
//       SEO_SET_NEWS: ['/news'],
//       SEO_SET_OPENCLASS: ['/openCourse'],
//     },
//     configData: []
//   }
//   render() {
//     const { keywords, description, title } = this.state;
//     return <div>
//       <Helmet>
//         <meta name="keywords" content={keywords} />
//         <meta name="description" content={description} />
//         <title>{title===null?'':title}</title>
//       </Helmet>

//       <RootRouter {...this.props} />
//     </div>
//   }

//   initdata = async () => {
//     const { configPath } = this.state;
//     const { pathname } = this.props.history.location;
//     let forRes = Object.keys(configPath).find(item => configPath[item].includes(pathname))
//     if (forRes !== undefined) {
//       let res = await IS_SUPPORT_SEO();
//       if(res.data.code === '1'&&res.data.data!==null){
//         let index = res.data.data.findIndex(item => item.type === forRes);
//         if (index !== -1) {
//           const { title, description, keywords } = res.data.data[index];
//           document.title = title;
//           this.setState({
//             title: title ? title : '',
//             description: description ? description : '',
//             keywords: keywords ? keywords : ''
//           })
//         }
//       }

//     }
//   }

//   componentDidMount() {
//     // this.initdata();
//   }
// }

export default function App() {
  return <InspectorWrapper data-inspector-line="86" data-inspector-column="9" data-inspector-relative-path="src/app.jsx" keys={['q', 'w', 'e']} disableLaunchEditor={false}>
          <RootRouter data-inspector-line="90" data-inspector-column="10" data-inspector-relative-path="src/app.jsx" />
        </InspectorWrapper>;
}