/*
 * @Author: zhongxue
 * @Date: 2020-09-06 21:02:00
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-12-16 10:37:21
 */
import axios from 'axios';
import { Local } from '../utils/local';
import { Decrypt } from '../utils/encrypt';
export default (() => {
  let baseURL = '';
  let MesInfo;
  if (window.location.host.indexOf('localhost') == -1) {
    baseURL = '/s/api';
  }
  let userId = '';
  let companyId = '';
  if (Local.get('userInfo')) {
    MesInfo = {
      ...JSON.parse(Decrypt(Local.get('userInfo')))
    };
    userId = MesInfo.userId ? MesInfo.userId : '';
    companyId = MesInfo.companyId ? MesInfo.companyId : '';
  }
  const axiosConfig = axios.create({
    baseURL,
    timeout: 100000,
    headers: {
      'content-type': 'application/json;charset=UTF-8',
      'Authorization': Local.get('token') ? Local.get('token') : "",
      userId,
      companyId
    }
  });
  // 请求拦截器
  // axiosConfig.interceptors.request.use(() => { })

  // 响应拦截器
  // axiosConfig.interceptors.response.use(() => { })
  return axiosConfig;
});