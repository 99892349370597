import { request, createParams } from '../utils-tools/request';
import { Local } from '../utils/local';
const commonPath = '/saas-business';
const domain = window.location.host.indexOf('localhost') == -1 ? window.location.origin : 'http://hxkht.rel-s.yunduoketang.cn';

// 校验教师名称是否重复
export async function CHECK_TEACHER_USERNAME(userName) {
  let response = await request(createParams(`${commonPath}/teacher/settled/checkTeacherAccount`, 'GET', {
    userName
  }));
  return response;
}

// 校验手机号是否存在
export async function CHECK_TEACHER_PHONENUM(phoneNum) {
  let response = await request(createParams(`${commonPath}/teacher/settled/checkTeacherMobile`, 'GET', {
    phoneNum
  }));
  return response;
}

// 发送短信
export async function SEND_MESSAGE(teacherMobile) {
  let response = await request(createParams(`${commonPath}/teacher/settled/sendRegisterTeacherSMS/${Local.get('schoolId')}/${teacherMobile}?domain=${domain}`, 'PUT'));
  return response;
}
// 短信验证码和手机号是否相关联
export async function CHECK_ON_MES(mobile, code) {
  let response = await request(createParams(`${commonPath}/teacher/settled/checkSmsCode/${mobile}/${code}`, 'POST'));
  return response;
}

// 教师入驻自定义字段获取GET 
export async function TEACHER_PAGE_INIT() {
  let response = await request(createParams(`${commonPath}/teacher/settled/initTeacherSettle/${Local.get('schoolId')}`, 'GET'));
  return response;
}

// 阿里云文件上传 
export async function UPLAODFILES() {
  let Path = `${window.location.origin}/s/api/outside/upload`;

  // if (env === 'development') {
  // 	Path = 'http://dev.api.yunduoweike.cn'
  // }

  let response = await request(createParams(`${Path}/outside/upload`, 'GET'));
  return response;
}
export async function TEACHER_SUBMIT(params) {
  let response = await request(createParams(`${commonPath}/teacher/settled/saveTeacherSettleData/${Local.get('schoolId')}`, 'POST', params));
  return response;
}