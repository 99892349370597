/*
 * @Author: zhongxue
 * @Date: 2020-09-06 21:02:00
 * @LastEditors  : zhongxue
 * @LastEditTime : 2020-09-07 10:00:00
 */

import createAxios from './createAxios';
// const env = process.env.NODE_ENV;
// const env = null;

// let mocks = null;

// if (env === 'development') {
//   mocks = require('../mock').default
// }
// 延时函数
// function delay(time) {
//   return new Promise(reslove => {
//     setTimeout(reslove, time);
//   })
// }
// async function requestMock({ url, method, data = {}, params = {} }) {
//   if (!mocks[`${url} ${method}`]) {
//     return { status: 404, msg: 'mock中不存在该接口' }
//   }
//   await delay(Math.random() * 1000);
//   let response = mocks[`${url} ${method}`](method === 'GET' ? params : data);
//   return response;
// }

export function createParams(url, method, data = {}) {
  return {
    url,
    method,
    data
  };
}
export async function request({
  url,
  method = 'GET',
  data
}) {
  let response = null;
  // if (env === 'development') {
  //   response = await requestMock({ url, method, [method === 'GET' ? 'params' : 'data']: data });
  //   return response;
  // }

  response = await createAxios()({
    url,
    method,
    [method === 'GET' ? 'params' : 'data']: data
  });
  return response;
}