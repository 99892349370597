import dynamic from 'dva/dynamic';
import app from '../dvaApp';
const Layout = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Layout")
});
const Home = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import('@/containers/Home')
});
const HomeIndex = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Home/HomeIndex")
});
const ChoiceSchool = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Home/ChoiceSchool")
});
const CoursePackage = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/CoursePackage")
});
const CoursePackageList = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/CoursePackage/List")
});
const CoursePackageDetail = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/CoursePackage/Detail")
});
const Course = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Course")
});
const CourseList = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Course/List")
});
const CourseDetail = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Course/Detail")
});
const AppointmentClass = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Course/Detail/AppointmentClass")
});
const OpenCourse = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/OpenCourse")
});
const Payment = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Payment")
});
const PaymentOrder = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Payment/PaymentOrder")
});
const PaymentResult = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Payment/PaymentResult")
});
const PaymentRemit = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Payment/PaymentRemit")
});
const PaymentScanCode = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Payment/PaymentScanCode")
});
const PaymentCourseAgreement = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/Payment/CourseAgreement")
});
const PersonalCenter = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter")
});
const AccountCenter = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/AccountCenter")
});
const AccountCenterInviteRecord = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/AccountCenter/InviteCode/InviteRecord")
});
const AccountCenterScoreDetail = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/AccountCenter/Score/ScoreDetail")
});
const CertificateHonor = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/CertificateHonor")
});
const CourseCenter = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import('@/containers/PersonalCenter/CourseCenter')
});
const MyMessage = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/MyMessage")
});
const OrderManage = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/OrderManage")
});
const OrderDetail = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/OrderManage/OrderDetail")
});
const Invoicing = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/OrderManage/OrderDetail/Invoicing")
});
const Detail = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/OrderManage/OrderDetail/Detail")
});
const PersonalQueAndAns = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/PersonalQueAndAns")
});
const HomeworkDetail = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import('@/containers/PersonalCenter/PersonalQueAndAns/Homework/HomeworkDetail')
});
const PersonalSetting = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/PersonalSetting")
});
const InsteadDetail = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/PersonalSetting/InsteadDetail")
});
const InsteadFill = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/PersonalSetting/InsteadFill")
});
const QuestionBankCenter = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/QuestionBankCenter")
});
const SubjectLibrary = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary")
});
const AutoPaper = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/AutoPaper")
});
const ExamPaper = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/ExamPaper")
});
const ApplyMark = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/ExamPaper/ApplyMark")
});
const ExamIntro = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/ExamPaper/ExamIntro")
});
const ExamReport = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/ExamPaper/ExamReport")
});
const PayResult = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/ExamPaper/PayResult")
});
const PaperIntro = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/PaperIntro")
});
const PaperPrint = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/PaperPrint")
});
const QuestionCollect = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/QuestionCollect")
});
const LookCollect = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/QuestionCollect/lookCollect")
});
const QuestionDoing = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/QuestionDoing")
});
const QuestionRecord = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/QuestionRecord")
});
const QuestionReport = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import('@/containers/SubjectLibrary/QuestionReport')
});
const QuestionWrong = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/QuestionWrong")
});
const WrongAgain = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/QuestionWrong/Again")
});
const StaticsCenter = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/StaticsCenter")
});
const SubjectDetail = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/SubjectDetail")
});
const SubjectHome = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/SubjectLibrary/SubjectHome")
});
const OldQuestionBankCenter = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/PersonalCenter/OldQuestionBankCenter")
});
const OpenPageCom = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/StreamViews/OpenPage")
});
const VideoViewCom = dynamic({
  app,
  models: () => [import('../models/course'), import('../models/coursepackage'), import('../models/layout'), import('../models/myInfo'), import('../models/payment'), import('../models/personal'), import('../models/subject')],
  component: () => import("@/containers/StreamViews/VideoView")
});

// 学员心声
const StudentsVoice = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../containers/StudentsVoice/index')
});

// 名师专区
const Teachers = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/teachers/index')
});
const TeachersDetail = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/teacherDetail/index')
});
const Tdetail = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/teacherDetail/detail')
});
// 小组
const Group = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/groups/index')
});
// 全部小组
const AllGroup = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/groups/allGroup')
});
// 小组详情
const GroupDetail = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/groups/groupDetail')
});
//创建小组
const CreateGroup = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/groups/create/index')
});
//创建话题
const CreateTopic = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/groups/createTopic/index')
});
//话题详情
const TopicDetail = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/groups/topicDetail')
});
//管理小组
const groupManage = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/groups/groupManage')
});
//新闻首页
const News = dynamic({
  app,
  component: () => import('../pages/news/index')
});
//新闻详情
const NewsDetails = dynamic({
  app,
  component: () => import('../pages/news/detail')
});

// 购买类会员专区入口
const VIP = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/vip/vipRouters')
});

//教师入驻注册页
const TeacherLoginIn = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../pages/teacherLoginIn/loginInRoutes')
});

// 问答列表
const AnswersList = dynamic({
  app,
  models: () => [import('../models/answers'), import('../models/layout')],
  component: () => import('../pages/answers/answersList')
});
// 所有回复
const Response = dynamic({
  app,
  models: () => [import('../models/response'), import('../models/layout')],
  component: () => import('../pages/answers/response')
});
// 提问页面
const AskQuestion = dynamic({
  app,
  models: () => [import('../models/askquestion'), import('../models/layout')],
  component: () => import('../pages/answers/askQuestion')
});
// app下载页
const AppDownLoad = dynamic({
  app,
  models: () => [],
  component: () => import('../pages/appDownLoad')
});

// 独立登录注册
const Join = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../containers/Join')
});

// 代报考
const ApplyExam = dynamic({
  app,
  models: () => [import('../models/layout')],
  component: () => import('../containers/ApplyExam')
});
const routes = [{
  component: Layout,
  routes: [
  // 首页
  {
    path: "/home",
    component: Home,
    routes: [{
      path: "/home/index",
      //首页
      component: HomeIndex
    }, {
      path: "/home/choice",
      //选择分校
      component: ChoiceSchool
    }]
  }, {
    path: "/vip",
    //会员专区
    component: VIP
  }, {
    path: "/teacherLoginIn",
    //教师入驻注册页
    component: TeacherLoginIn
  }, {
    path: "/answerslist/:expertFlag",
    //问答列表
    component: AnswersList
  }, {
    path: "/response/:questionid",
    //所有回复
    component: Response
  }, {
    path: '/askquestion/:expertFlag',
    // 提问页面
    component: AskQuestion
  }, {
    path: '/appload',
    // app下载
    component: AppDownLoad
  },
  // 课程
  {
    path: "/course",
    component: Course,
    routes: [{
      path: "/course/list",
      component: CourseList
    }, {
      path: "/course/detail/:id/:classTypeId",
      component: CourseDetail
    }, {
      path: "/course/appointment/:stuId/:modulenoId/:masterId/:classTypeId",
      component: AppointmentClass
    }]
  },
  // 课程包
  {
    path: "/coursePackage",
    component: CoursePackage,
    routes: [{
      path: "/coursePackage/list",
      component: CoursePackageList
    }, {
      path: "/coursePackage/detail/:id",
      component: CoursePackageDetail
    }]
  },
  // 公开课
  {
    path: "/openCourse",
    component: OpenCourse
  },
  // 支付
  {
    path: "/payment",
    component: Payment,
    routes: [{
      path: "/payment/order/:orderId",
      exact: true,
      component: PaymentOrder
    }, {
      path: "/payment/result/:orderId",
      exact: true,
      component: PaymentResult
    }, {
      path: "/payment/courseagreement/:protocolId/:commodityType/:commodityId",
      exact: true,
      component: PaymentCourseAgreement
    }, {
      path: "/payment/scancode",
      exact: true,
      component: PaymentScanCode
    }, {
      path: "/payment/remit/:orderId",
      exact: true,
      component: PaymentRemit
    }]
  }, {
    path: "/lib",
    component: SubjectLibrary,
    routes: [{
      path: "/lib/subject",
      exact: true,
      component: SubjectHome
    }, {
      path: "/lib/subject/detail/:categoryId/:subjectId/:activeKey",
      exact: true,
      component: SubjectDetail
    }, {
      path: "/lib/subject/doing/:recordId/:paperType/:paperMode",
      exact: true,
      component: QuestionDoing
    }, {
      path: "/lib/subject/report/:recordId/:paperType/:paperMode",
      //做题报告
      exact: true,
      component: QuestionReport
    }, {
      path: "/lib/subject/statics/:categoryId/:subjectId",
      //数据中心
      exact: true,
      component: StaticsCenter
    }, {
      path: "/lib/subject/auto/:categoryId/:subjectId",
      //智能组卷
      exact: true,
      component: AutoPaper
    }, {
      path: "/lib/subject/paperintro/:categoryId/:subjectId/:paperId/:paperType/:paperMode/:recordId",
      //试卷介绍
      exact: true,
      component: PaperIntro
    }, {
      path: "/lib/subject/paperprint/:recordId/:paperType/:paperMode",
      //试卷打印
      exact: true,
      component: PaperPrint
    }, {
      path: "/lib/subject/record/:subjectId",
      //做题记录
      exact: true,
      component: QuestionRecord
    }, {
      path: "/lib/subject/wrong/:subjectId/:categoryId",
      //错题集
      exact: true,
      component: QuestionWrong
    }, {
      path: "/lib/subject/wrong/:value/:subjectId/:typeId/:categoryId",
      //错题集-再做一次
      exact: true,
      component: WrongAgain
    }, {
      path: "/lib/subject/collect/:subjectId/:categoryId",
      //收藏夹
      exact: true,
      component: QuestionCollect
    }, {
      path: "/lib/subject/collect/look/:subjectId/:typeId/:categoryId",
      //收藏夹-查看
      exact: true,
      component: LookCollect
    }, {
      path: "/lib/subject/exampaper",
      //考试结果相关页面
      exact: true,
      component: ExamPaper
    }, {
      path: "/lib/subject/exampaper/examintro/:examType/:examId",
      //考试介绍
      exact: true,
      component: ExamIntro
    }, {
      path: "/lib/subject/exampaper/examreport/:reportId/:paperType",
      //证书、抽题、付费类考试报告
      exact: true,
      component: ExamReport
    }, {
      path: "/lib/subject/exampaper/applymark/:exerciseId",
      //申请批阅
      exact: true,
      component: ApplyMark
    }, {
      path: "/lib/subject/exampaper/payresult/:id/:result",
      //付费结果
      exact: true,
      component: PayResult
    }]
  }, {
    path: "/personal",
    //个人中心
    component: PersonalCenter,
    routes: [{
      path: "/personal/questionbankcenter",
      //题库中心
      component: QuestionBankCenter
    }, {
      path: "/personal/oldquestionbankcenter",
      //题库中心
      component: OldQuestionBankCenter
    }, {
      path: "/personal/study",
      // 学习问答
      component: PersonalQueAndAns
    }, {
      path: "/personal/homeworkdetail/:id/:papertype/:paperid",
      // 学习问答-作业-详情
      component: HomeworkDetail
    }, {
      path: "/personal/accountcenter",
      // 账户中心
      component: AccountCenter
    }, {
      path: "/personal/account/scoredetail",
      // 账户中心-积分说明
      component: AccountCenterScoreDetail
    }, {
      path: "/personal/account/inviterecord",
      // 账户中心-邀请记录
      component: AccountCenterInviteRecord
    }, {
      path: "/personal/course",
      // 课程中心
      component: CourseCenter
    }, {
      path: "/personal/certificate",
      // 荣誉证书
      component: CertificateHonor
    }, {
      path: "/personal/order",
      // 订单管理
      component: OrderManage
    }, {
      path: "/personal/:id/:invoiceStatus/orderDetail",
      // 订单管理-订单详情
      // exact: true,
      component: OrderDetail
    }, {
      path: "/personal/:id/:invoiceStatus/orderDetail/detail",
      // 订单管理-订单详情-详情
      // exact: true,
      component: Detail
    }, {
      path: "/personal/:id/:invoiceStatus/orderDetail/invoicing",
      // 订单管理-订单详情-开具发票
      // exact: true,
      component: Invoicing
    }, {
      path: "/personal/setting",
      // 个人设置
      component: PersonalSetting
    }, {
      path: "/personal/instead/detail/:id",
      //代报考资料详情
      component: InsteadDetail
    }, {
      path: "/personal/instead/fill/:id",
      //代报考资料填写
      component: InsteadFill
    }, {
      path: "/personal/message",
      // 我的消息
      component: MyMessage
    }]
  }, {
    path: "/open/page/:classType/:type",
    component: OpenPageCom
  }, {
    path: "/class/type/:type/page",
    component: VideoViewCom
  }, {
    path: "/apply",
    // 代报考
    component: ApplyExam
  }, {
    path: "/join/:type",
    component: Join
  }, {
    path: "/StudentsVoice",
    component: StudentsVoice
  }, {
    path: "/teachers",
    component: Teachers
  }, {
    path: "/teachersdetail",
    component: TeachersDetail
  }, {
    path: "/tdetail",
    component: Tdetail
  }, {
    path: "/group",
    component: Group
  }, {
    path: "/allgroup",
    component: AllGroup
  }, {
    path: "/groupdetail/:id",
    component: GroupDetail
  }, {
    path: "/creategroup",
    component: CreateGroup
  }, {
    path: "/createtopic",
    component: CreateTopic
  }, {
    path: "/groupManage",
    component: groupManage
  }, {
    path: "/topicdetail",
    component: TopicDetail
  }, {
    path: "/news",
    component: News
  }, {
    path: "/newsdetails/:id",
    component: NewsDetails
  }, {
    path: "*",
    component: HomeIndex
  }]
}];
export default routes;