import React, { PureComponent, CommonUI } from 'react';
import { Upload, Icon, message, Button, Modal } from 'antd';
import { fileStyle, nameShow, uploadTips, errorStyle } from './index.module.less';
const {
  Dragger
} = Upload;
const {
  confirm
} = Modal;
class DragUpload extends PureComponent {
  state = {
    domain: window.location.origin,
    origin: `${window.location.origin}/s/api`,
    showFilesName: '',
    errortips: ''
  };
  render() {
    const {
      domain,
      origin,
      showFilesName,
      errortips
    } = this.state;
    const {
      isDragger,
      extraTxt,
      form,
      cityKeys
    } = this.props;
    const accept = isDragger ? ".jpg,.png" : ".pdf,.doc,.xls,.ppt,.docx,.xlsx,.pptx,.zip,.rar";
    const types = isDragger ? 'pic' : 'file';
    const UploadCom = isDragger ? Dragger : Upload;
    return <>
			{showFilesName === '' ? <>
				<div data-inspector-line="26" data-inspector-column="4" data-inspector-relative-path="src/common/dragUpload/index.com.jsx" style={{
          width: 334
        }}>
				<UploadCom data-inspector-line="27" data-inspector-column="4" data-inspector-relative-path="src/common/dragUpload/index.com.jsx" accept={accept} showUploadList={false} name="file" action={`${origin}/saas-business/teacher/settled/uploadFile?type=${types}&domain=${domain}`} style={{
            backgroundColor: '#fff'
          }} onChange={info => {
            const {
              status
            } = info.file;
            if (status === 'done') {
              const fileType = info.file.name.split('.')[1];
              if (accept.indexOf(fileType) === -1) {
                message.error(`请上传${accept}格式的内容`);
                this.setState({
                  showFilesName: '',
                  errortips: `请上传${accept}格式的内容`
                });
                form.setFieldsValue({
                  [cityKeys]: []
                });
                return;
              }
              message.success(`${info.file.name}上传成功`);
              this.setState({
                showFilesName: info.file.name,
                errortips: ''
              });
              log(info.fileList);
              if (cityKeys) {
                form.setFieldsValue({
                  [cityKeys]: [...info.fileList.slice(-1)]
                });
              }
            }
            if (status === 'error') {
              this.setState({
                showFilesName: '',
                errortips: `${info.file.name}上传失败`
              });
              message.error(`${info.file.name}上传失败`);
            }
          }}>
					{isDragger ? <div data-inspector-line="59" data-inspector-column="16" data-inspector-relative-path="src/common/dragUpload/index.com.jsx">
							<p data-inspector-line="60" data-inspector-column="7" data-inspector-relative-path="src/common/dragUpload/index.com.jsx" className="ant-upload-drag-icon">
								<Icon data-inspector-line="61" data-inspector-column="8" data-inspector-relative-path="src/common/dragUpload/index.com.jsx" type="cloud-upload" style={{
                  color: '#D3D6D8'
                }} />
							</p>
							<p data-inspector-line="63" data-inspector-column="7" data-inspector-relative-path="src/common/dragUpload/index.com.jsx" className={fileStyle}>将文件拖拽至此处或<span data-inspector-line="63" data-inspector-column="41" data-inspector-relative-path="src/common/dragUpload/index.com.jsx">点击上传</span></p>
						</div> : <Button data-inspector-line="65" data-inspector-column="7" data-inspector-relative-path="src/common/dragUpload/index.com.jsx" icon="upload">上传资料</Button>}
				</UploadCom>
				</div>
				{errortips === '' || errortips === undefined ? null : <div data-inspector-line="71" data-inspector-column="53" data-inspector-relative-path="src/common/dragUpload/index.com.jsx" className={errorStyle}>{errortips}</div>}
				<p data-inspector-line="73" data-inspector-column="4" data-inspector-relative-path="src/common/dragUpload/index.com.jsx" className={uploadTips}>{extraTxt}</p>
				</> : <div data-inspector-line="75" data-inspector-column="5" data-inspector-relative-path="src/common/dragUpload/index.com.jsx" className={nameShow}>
					<b data-inspector-line="76" data-inspector-column="5" data-inspector-relative-path="src/common/dragUpload/index.com.jsx">{showFilesName}</b>
					<CommonUI.Icon data-inspector-line="77" data-inspector-column="5" data-inspector-relative-path="src/common/dragUpload/index.com.jsx" name="guanbi1" color="#D3D6D8" onClick={() => {
          confirm({
            title: '提示',
            content: '确定要删除当前上传的文件吗？',
            onOk: () => {
              const {
                form,
                cityKeys
              } = this.props;
              this.setState({
                showFilesName: '',
                errortips: ''
              });
              form.setFieldsValue({
                [cityKeys]: ''
              });
            }
          });
        }} />
				</div>}
			</>;
  }
}
DragUpload.defaultProps = {
  onChange: value => log(value)
};
export default DragUpload;