import { trim } from 'lodash';
export function caculateLen(name) {
  let value = trim(name);
  let len = 0;
  for (var i = 0; i < value.length; i++) {
    if (value.charCodeAt(i) > 127 || value.charCodeAt(i) == 94) {
      len += 2;
    } else {
      len++;
    }
  }
  return len;
}

// 过滤特殊字符
export function stripScript(str) {
  var pattern = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]");
  var rs = "";
  for (var i = 0; i < str.length; i++) {
    rs = rs + str.substr(i, 1).replace(pattern, '');
  }
  return rs;
}

// 过滤html标签
export function formatHtml(str) {
  if (str) {
    // return str.replace(/<[^<>]+>/g,"").replace(/\s/g, "")
    return str.replace(/<[^<>]+>/g, "").trim();
  } else {
    return "";
  }
}

// 判断是否为空对象
export function isEmptyObject(obj) {
  for (var key in obj) {
    //返回false，不为空对象
    return false;
  }
  //返回true，为空对象
  return true;
}

// 替换字符串中的img对象
export function formatImgStr(str, score) {
  const mediaReg = /<img\b.*?(?:\>|\/>)/gi;
  const mediaArr = str && str.match(mediaReg);
  let newQsName = str,
    newVido = '',
    newAudio = '';
  if (mediaArr && mediaArr.length) {
    mediaArr.forEach(v => {
      if (v.indexOf('video') !== -1) {
        // let videoAttrReg = /<img id="(.*?)" data-time="(.*?)" (.*?)\/?>/ig
        let videoIdReg = /<img.*?id="(.*?)".*?\/?>/ig;
        let videoTimeReg = /<img.*?data-time="(.*?)".*?\/?>/ig;
        let videoIdRegArr = videoIdReg.exec(v);
        let videoTimeRegArr = videoTimeReg.exec(v);
        if (videoIdRegArr && videoIdRegArr.length && videoTimeRegArr && videoTimeRegArr.length) {
          let time = "";
          // 00：00：00转换为 00：00  00：00不变
          if (videoTimeRegArr[1].split(":").length == 3) {
            time = videoTimeRegArr[1] === "undefined" || videoTimeRegArr[1] === "null" ? "00:00" : videoTimeRegArr[1].split(":")[0] * 60 + Number(videoTimeRegArr[1].split(":")[1]) < 10 ? "0" + (videoTimeRegArr[1].split(":")[0] * 60 + Number(videoTimeRegArr[1].split(":")[1]) + ":" + videoTimeRegArr[1].split(":")[2]) : videoTimeRegArr[1].split(":")[0] * 60 + Number(videoTimeRegArr[1].split(":")[1]) + ":" + videoTimeRegArr[1].split(":")[2];
          } else if (videoTimeRegArr[1].split(":").length == 2) {
            time = videoTimeRegArr[1];
          }
          newVido = v.replace(/<img (.*?)\/?>/i, `<span class="video-img" id=${videoIdRegArr[1]}>
              <i class="iconfont iconshipin" id=${videoIdRegArr[1]}></i>
              <span id=${videoIdRegArr[1]}>${time}</span>
            </span>`);
        }
        newQsName = newQsName.replace(v, newVido);
      }
      if (v.indexOf('audio') !== -1) {
        let audioIdReg = /<img.*?id="(.*?)".*?\/?>/ig;
        let audioTimeReg = /<img.*?data-time="(.*?)".*?\/?>/ig;
        let audioIdRegArr = audioIdReg.exec(v);
        let audioTimeRegArr = audioTimeReg.exec(v);
        if (audioIdRegArr && audioIdRegArr.length && audioTimeRegArr && audioTimeRegArr.length) {
          let time = "";
          // 00：00：00转换为 00：00  00：00不变
          if (audioTimeRegArr[1].split(":").length == 3) {
            time = audioTimeRegArr[1] === "undefined" || audioTimeRegArr[1] === '' ? "00:00" : audioTimeRegArr[1].split(":")[0] * 60 + Number(audioTimeRegArr[1].split(":")[1]) < 10 ? "0" + (audioTimeRegArr[1].split(":")[0] * 60 + Number(audioTimeRegArr[1].split(":")[1]) + ":" + audioTimeRegArr[1].split(":")[2]) : audioTimeRegArr[1].split(":")[0] * 60 + Number(audioTimeRegArr[1].split(":")[1]) + ":" + audioTimeRegArr[1].split(":")[2];
          } else if (audioTimeRegArr[1].split(":").length == 2) {
            time = audioTimeRegArr[1];
          }
          newAudio = v.replace(/<img(.*?)\/?>/i, `<span class="audio-img" id=${audioIdRegArr[1]}>
            <i class="iconfont iconyuyin" id=${audioIdRegArr[1]}></i>
            <span id=${audioIdRegArr[1]}>${time}</span>
           </span>`);
        }
        newQsName = newQsName.replace(v, newAudio);
        // v.replace(/<img [^>]*id=['"]([^'"]+)[^>]*>/gi,function (match, capture) {
        //   newAudio = v.replace(/<img(.*?)\/?>/i, `<span class="audio-img" id=${capture}><img src=${require("@/statics/img/icon_audio.png")} alt="" /><span>00:00:26</span></span>`);
        //   newQsName = newQsName.replace(v, newAudio)
        // })
      }

      if (v.indexOf('id="image') !== -1) {
        let newImage = v.replace(/<img (.*?)\/?>/i, `<br/>${v}`);
        newQsName = newQsName.replace(v, newImage);
      }
    });
  }
  let result = score ? `${newQsName}（${score}分）` : newQsName;
  return result;
}

// 超出字省略号代替
export function formatEllipsis(str) {
  if (str.length > 25) {
    return str.substring(0, 25) + "...";
  } else {
    return str;
  }
}

// 视频解析图片美化
export function formatVideo(str) {
  const mediaReg = /<img\b.*?(?:\>|\/>)/gi;
  const mediaArr = str.match(mediaReg);
  let newQsName = str,
    newVido = '';
  if (mediaArr && mediaArr.length) {
    mediaArr.forEach(v => {
      if (v.indexOf('video') !== -1) {
        let videoIdReg = /<img.*?id="(.*?)".*?\/?>/ig;
        let videoSrcReg = /<img.*?src="(.*?)".*?\/?>/ig;
        let videoTimeReg = /<img.*?data-time="(.*?)".*?\/?>/ig;
        let videoIdRegArr = videoIdReg.exec(v);
        let videoSrcRegArr = videoSrcReg.exec(v);
        let videoTimeRegArr = videoTimeReg.exec(v);
        if (videoIdRegArr && videoIdRegArr.length && videoSrcRegArr && videoSrcRegArr.length && videoTimeRegArr && videoTimeRegArr.length) {
          let time = videoTimeRegArr[1] === "undefined" || videoTimeRegArr[1] === "null" ? "00:00" : videoTimeRegArr[1].split(":")[0] * 60 + Number(videoTimeRegArr[1].split(":")[1]) < 10 ? "0" + (videoTimeRegArr[1].split(":")[0] * 60 + Number(videoTimeRegArr[1].split(":")[1]) + ":" + videoTimeRegArr[1].split(":")[2]) : videoTimeRegArr[1].split(":")[0] * 60 + Number(videoTimeRegArr[1].split(":")[1]) + ":" + videoTimeRegArr[1].split(":")[2];
          newVido = v.replace(/<img (.*?)\/?>/i, `<p class="analysis-video-img">
              <img class="bg-img" src=${"http://image.xiaozhuoedu.com/temp/public/20191211/9975f392-d667-49d8-a52e-3648509aaec3.png" !== videoSrcRegArr[1] ? videoSrcRegArr[1] : null} alt="" data-flag=${videoIdRegArr[1]} />
              <img class="stop-img" src=${require("@/statics/img/icon_zanting.png")} alt="" data-flag=${videoIdRegArr[1]} />
              <span class="time-img" data-flag=${videoIdRegArr[1]}>${time}</span>
            </p>`);
        }
        newQsName = newQsName.replace(v, newVido);
      }
    });
  }
  return newQsName;
}

// url初始化成对象
export function getPersonalMessage(str) {
  console.log(str, 'getPersonalMessage==str');
  let search = str && str.split("?")[1].split("&");
  let searchObj = {};
  console.log(searchObj, search, 'getPersonalMessage==str');
  searchObj.code = search.length && search[1].substr(5);
  return searchObj;
}
export function isInteger(num) {
  let Num = Number(num);
  if (Num % 1 === 0) {
    return parseInt(Num);
  } else {
    return Num.toFixed(1);
  }
}

//用户登录方法（需要判断是否关闭了独立登陆页，useLoginPage表示是否使用登录页面：0否，1是）
export function goLogin() {
  console.log('用户登录方法（需要判断是否关闭了独立登陆页，useLoginPage表示是否使用登录页面：0否，1是）');
  if (Local.get('useLoginPage')) {
    let hash = '';
    //判断hash路径中是否含有code参数，有的话跳转登录的时候去掉，以防未登录code覆盖已登录用户信息；
    //目前的判断方法过于绝对，可能会把页面需要的其他参数去掉，后面可能还需要优化
    if (window.location.hash.indexOf('?code=') > -1) {
      hash = `${window.location.pathname}${window.location.hash.split('?')[0]}`;
    } else {
      hash = `${window.location.pathname}${window.location.hash}`;
    }
    let queryString = window.encodeURIComponent(hash);
    let useLoginPage = Local.get('useLoginPage');
    if (useLoginPage == 1) {
      // const hash = `${window.location.pathname}${window.location.hash}`
      // const queryString = window.encodeURIComponent(hash)
      window.location.href = `${window.location.origin}/loginPage?nqbcallback=${queryString}`;
    } else {
      window.location.href = `${window.location.origin}?popwin=login&returnUrl=${queryString}`;
    }
  }
  Local.remove('token');
  Local.remove('userInfo');
  Local.remove('schoolId');
  Local.remove('main_color');
  Local.remove('useLoginPage');
}

// 时间格式化
export function formatDate(time, fmt) {
  fmt = fmt || 'yyyy-MM-dd HH:mm:ss';
  let date = new Date(time);
  let obj = {
    'y': date.getFullYear(),
    // 年份，注意必须用getFullYear
    'M': date.getMonth() + 1,
    // 月份，注意是从0-11
    'd': date.getDate(),
    // 日期
    'w': date.getDay(),
    'H': date.getHours(),
    // 24小时制
    'h': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
    // 12小时制
    'm': date.getMinutes(),
    // 分钟
    's': date.getSeconds(),
    // 秒
    'S': date.getMilliseconds() // 毫秒
  };

  let week = ['日', '一', '二', '三', '四', '五', '六'];
  for (let i in obj) {
    fmt = fmt.replace(new RegExp(i + '+', 'g'), function (value) {
      let val = obj[i] + '';
      if (i === 'w') return (value.length > 2 ? '星期' : '周') + week[val];
      for (let j = 0, len = val.length; j < value.length - len; j++) val = '0' + val;
      return value.length === 1 ? val : val.substring(val.length - value.length);
    });
  }
  return fmt;
}

//计算距离当前时间 时间差
export function getTimeDiff(time) {
  console.log(new Date(time));
  var diff = '';
  var time_diff = time - new Date().getTime();
  if (time_diff <= 0) {
    diff = "00:00:00";
  } else {
    // 计算相差天数
    var days = Math.floor(time_diff / (24 * 3600 * 1000));
    if (days > 0) {
      diff += days + '天';
    }
    console.log('time_diff', diff);
    // 计算相差小时数
    var leave1 = time_diff % (24 * 3600 * 1000);
    var hours = Math.floor(leave1 / (3600 * 1000));
    if (hours > 0 && hours.toString().length > 1) {
      diff += hours + ':';
    } else if (hours > 0) {
      diff += '0' + hours + ':';
    } else {
      if (diff !== '') {
        diff += hours + ':';
      } else {
        diff += "00:";
      }
    }
    // 计算相差分钟数
    var leave2 = leave1 % (3600 * 1000);
    var minutes = Math.floor(leave2 / (60 * 1000));
    if (minutes > 0) {
      diff += minutes + ':';
    } else {
      if (diff !== '') {
        diff += minutes + ':';
      }
    }
    // 计算相差秒数
    var leave3 = leave2 % (60 * 1000);
    var seconds = Math.round(leave3 / 1000);
    if (seconds > 0) {
      diff += seconds;
    } else {
      if (diff !== '') {
        diff += seconds;
      }
    }
    time = diff;
    console.log('diff', diff);
    return time;
  }
}

// 文件大小格式化
export function formatFileSize(size) {
  let fileSize = (size / 1024).toFixed(2); // kb
  let showSize = `${fileSize}KB`;
  if (fileSize >= 1024) {
    fileSize = (fileSize / 1024).toFixed(2); // mb
    showSize = `${fileSize}MB`;
    if (fileSize >= 1024) {
      fileSize = (fileSize / 1024).toFixed(2); // gb
      showSize = `${fileSize}GB`;
    }
  }
  return showSize;
}

//时间戳处理 年月日
export function formatTime(time) {
  let date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = date.getDate() + ' ';
  return Y + M + D;
}

//时间戳处理 年月日时分秒
export function formatTimeFull(time) {
  let date = new Date(time); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + '-';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  let D = date.getDate() + ' ';
  let h = date.getHours();
  h = h < 10 ? '0' + h : h;
  let m = date.getMinutes();
  m = m < 10 ? '0' + m : m;
  let s = date.getSeconds();
  s = s < 10 ? '0' + s : s;
  return Y + M + D + h + ':' + m + ':' + s;
}

//限制金额输入只能为0或正数并且最多只能输入两位小数
export function limitAmountInput(value) {
  value = value.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
  value = value.replace(/^\./g, "0."); //必须保证第一个为数字而不是.
  value = value.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
  value = value.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  value = value.replace(/^(\-)*(\d+)\.(\d{2}).*$/, '$1$2.$3'); //只能输入2个小数
  return value;
}
//生成UUID
export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}

// 图片异步处理
function onLoad(src) {
  const img = new Image();
  img.src = src;
  img.crossOrigin = "anonymous";
  img.style.display = 'none';
  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = () => resolve(false);
  });
}

// 图片色值像素点处理
export async function formatImgArr({
  id = 'canvas',
  width,
  height,
  src
}) {
  let canvas = document.getElementById(id);
  let ctx = canvas.getContext('2d');
  let data = [];
  let resImg = await onLoad(src);
  ctx.drawImage(resImg, 0, 0, width, height);
  data = ctx.getImageData(0, 0, resImg.width, resImg.height).data;
  let rgbArray = [];
  for (let i = 0; i < data.length; i += 4) {
    let rdata = data[i]; // 240-250
    let gdata = data[i + 1]; // 70-100
    let bdata = data[i + 2]; // 80-120
    let adata = data[i + 3];
    if (adata > 125) {
      rgbArray.push([rdata, gdata, bdata, adata]);
    }
  }
  return getMainColor(rgbArray);
}

// 获取图片主色
const qulity = 100;
let mainColor = '';
export function getMainColor(cube) {
  let maxr = cube[0][0],
    minr = cube[0][0],
    maxg = cube[0][1],
    ming = cube[0][1],
    maxb = cube[0][2],
    minb = cube[0][2];
  for (var i = 0; i < cube.length; i++) {
    if (cube[i][0] > maxr) {
      maxr = cube[i][0];
    }
    if (cube[i][0] < minr) {
      minr = cube[i][0];
    }
    if (cube[i][1] > maxg) {
      maxg = cube[i][1];
    }
    if (cube[i][1] < ming) {
      ming = cube[i][1];
    }
    if (cube[i][2] > maxb) {
      maxb = cube[i][2];
    }
    if (cube[i][2] < minb) {
      minb = cube[i][2];
    }
  }
  if (maxr - minr < qulity && maxg - ming < qulity && maxb - minb < qulity) {
    let r = 0,
      g = 0,
      b = 0;
    for (let i = 0; i < cube.length; i++) {
      r += cube[i][0];
      g += cube[i][1];
      b += cube[i][2];
    }
    mainColor = `${r / cube.length},${g / cube.length},${b / cube.length}`;
  } else {
    var maxrgb = 0;
    var rgbindex = 0;
    var rgbmiddle = 0;
    if (maxr - minr > maxrgb) {
      maxrgb = maxr - minr;
      rgbmiddle = (maxr + minr) / 2;
      rgbindex = 0;
    }
    if (maxg - ming > maxrgb) {
      maxrgb = maxg - ming;
      rgbmiddle = (maxg + ming) / 2;
      rgbindex = 1;
    }
    if (maxb - minb > maxrgb) {
      maxrgb = maxb - minb;
      rgbmiddle = (maxb + minb) / 2;
      rgbindex = 2;
    }
    //排序
    cube.sort(function (x, y) {
      return x[rgbindex] - y[rgbindex];
    });
    var cubea = new Array();
    var cubeb = new Array();
    for (var i = 0; i < cube.length; i++) {
      if (cube[i][rgbindex] < rgbmiddle) {
        cubea.push(cube[i]);
      } else {
        cubeb.push(cube[i]);
      }
    }
    getMainColor(cubeb);
    getMainColor(cubea);
  }
  return mainColor;
}

// 笔记处理时间
export function formatSeconds(value) {
  if (!value) {
    return "00:00";
  }
  var theTime = parseInt(value); // 秒
  var theTime1 = 0; // 分
  var theTime2 = 0; // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
    }
  }
  theTime = parseInt(theTime) + "";
  theTime = theTime.length < 2 ? theTime = "0" + theTime : theTime;
  var result = theTime;
  if (theTime1 > 0) {
    theTime1 = parseInt(theTime1) + "";
    theTime1 = theTime1.length < 2 ? theTime1 = "0" + theTime1 : theTime1;
    result = theTime1 + ":" + result;
  } else {
    result = "00:" + result;
  }
  if (theTime2 > 0) {
    theTime2 = parseInt(theTime2) + "";
    theTime2 = theTime2.length < 2 ? theTime2 = "0" + theTime2 : theTime2;
    result = theTime2 + ":" + result;
  } else {
    "00:" + result;
  }
  return result;
}
export function formatWanNum(num) {
  if (num >= 10000) {
    let newNum = (num / 10000).toString();
    return newNum.includes('.') ? `${newNum.split('.')[0]}${newNum.split('.')[1].slice(0, 1) == 0 ? '' : `.${newNum.split('.')[1].slice(0, 1)}`}w+` : newNum + 'w+';
  } else {
    return num;
  }
}