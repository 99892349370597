/*
 * @Author: 张松涛
 * @Date: 2020-09-25 15:56:14 
 * @Last Modified by: mikey.zhaopeng
 * @Last Modified time: 2020-09-25 17:03:11
 * @props [value] { number }  分数
 * @props [size] { number } 大小
 * @props [margin] { number } 距离顶部的边界值
 */

import React, { Component } from 'react';
import stars from '../../statics/img/star1.png';
import starn from '@/statics/img/star2.png';
import css from './index.module.less';
export default class StarView extends Component {
  static defaultProps = {
    value: 0,
    size: 14,
    margin: 0
  };
  state = {
    star1: 0,
    star2: 0
  };
  componentDidMount() {
    //分割分数
    let Arr = this.props.value.toString().split('.');
    this.setState({
      star1: Number(Arr[0]),
      star2: Number(Arr[1])
    });
  }
  // 渲染出实心得星星
  solidStar(num) {
    let str = '';
    for (let i = 0; i < num; i++) {
      str += `<img src=${starn} alt=""/>`;
    }
    return str;
  }
  render() {
    const {
      star1,
      star2
    } = this.state;
    const {
      size,
      margin
    } = this.props;
    return <div data-inspector-line="46" data-inspector-column="12" data-inspector-relative-path="src/common/starView/index.com.jsx" className={css.starView} style={{
      marginTop: `${margin}px`
    }}>
                <div data-inspector-line="47" data-inspector-column="16" data-inspector-relative-path="src/common/starView/index.com.jsx" className={css.view}>
                    {star1 ? <div data-inspector-line="49" data-inspector-column="32" data-inspector-relative-path="src/common/starView/index.com.jsx" className={css.star1} style={{
          height: `${size}px`
        }} dangerouslySetInnerHTML={{
          __html: this.solidStar(star1)
        }}>
                                </div> : null}                   
                    {star2 ? <div data-inspector-line="53" data-inspector-column="32" data-inspector-relative-path="src/common/starView/index.com.jsx" className={css.star2} style={{
          width: `${size}px`,
          height: `${size}px`
        }}>
                                    <img data-inspector-line="54" data-inspector-column="36" data-inspector-relative-path="src/common/starView/index.com.jsx" src={stars} alt="" className={css.diImg} />
                                    <div data-inspector-line="55" data-inspector-column="36" data-inspector-relative-path="src/common/starView/index.com.jsx" className={css.caiImg} style={{
            width: `${size / 10 * star2}px`,
            height: `${size}px`
          }}>
                                        <img data-inspector-line="56" data-inspector-column="40" data-inspector-relative-path="src/common/starView/index.com.jsx" src={starn} alt="" style={{
              width: `${size}px`,
              height: `${size}px`
            }} />
                                    </div>
                                </div> : null}
                </div>
            </div>;
  }
}