import React, { PureComponent } from 'react';
import { Select, Row, Col, Input } from 'antd';
import cityData from './cityArea.json';
const {
  Option
} = Select;
class CitySelect extends PureComponent {
  state = {
    provinceArr: cityData.map(item => item.name),
    cityArr: [],
    areaArr: [],
    provinceValue: "请选择",
    cityValue: "请选择",
    areaValue: "请选择",
    printValue: ""
  };
  getProvinceValue = value => {
    let obj = cityData.find(item => item.name === value);
    if (obj.city.length) {
      let cityArr = obj.city.map(item => item.name);
      this.setState({
        provinceValue: value,
        cityValue: "请选择",
        areaValue: "请选择",
        cityArr: [...cityArr],
        areaArr: []
      }, () => this.printValue());
    }
  };
  getCityValue = value => {
    const {
      provinceValue
    } = this.state;
    let obj = cityData.find(item => item.name === provinceValue);
    obj = obj.city.find(item => item.name === value);
    if (obj.area.length) {
      this.setState({
        cityValue: value,
        areaValue: "请选择",
        areaArr: [...obj.area]
      }, () => this.printValue());
    }
  };
  getAreaValue = value => this.setState({
    areaValue: value
  }, () => this.printValue());
  printValue = () => {
    const {
      provinceValue,
      cityValue,
      areaValue,
      printValue
    } = this.state;
    const {
      form,
      cityKeys,
      printIsShow
    } = this.props;
    form.setFieldsValue({
      [cityKeys]: `${provinceValue}-${cityValue}-${areaValue}${['', '-' + printValue][printIsShow]}`
    });
  };

  // 设置地址输入框的值
  changePrintValue = value => this.setState({
    printValue: `${value}`
  }, () => this.printValue());
  render() {
    const {
      provinceArr,
      cityArr,
      areaArr,
      provinceValue,
      cityValue,
      areaValue,
      printValue
    } = this.state;
    const {
      printIsShow
    } = this.props;
    return <Row data-inspector-line="49" data-inspector-column="12" data-inspector-relative-path="src/common/citySelect/index.com.jsx">
                <Col data-inspector-line="50" data-inspector-column="16" data-inspector-relative-path="src/common/citySelect/index.com.jsx" span={4}>
                    <Select data-inspector-line="51" data-inspector-column="20" data-inspector-relative-path="src/common/citySelect/index.com.jsx" style={{
          width: '100%',
          paddingRight: 15
        }} defaultValue="请选择" value={provinceValue} onChange={value => this.getProvinceValue(value)}>
                        {/* <Option value="请选择" >请选择省份</Option> */}
                        {provinceArr.map((item, keys) => <Option data-inspector-line="54" data-inspector-column="57" data-inspector-relative-path="src/common/citySelect/index.com.jsx" key={keys} value={item}>{item}</Option>)}
                    </Select>
                </Col>
                <Col data-inspector-line="58" data-inspector-column="16" data-inspector-relative-path="src/common/citySelect/index.com.jsx" span={4}>
                    <Select data-inspector-line="59" data-inspector-column="20" data-inspector-relative-path="src/common/citySelect/index.com.jsx" style={{
          width: '100%',
          paddingRight: 15
        }} defaultValue="请选择" value={cityValue} onChange={value => this.getCityValue(value)}>
                        {/* <Option value="请选择">请选择城市</Option> */}
                        {cityArr.map((item, keys) => <Option data-inspector-line="62" data-inspector-column="53" data-inspector-relative-path="src/common/citySelect/index.com.jsx" key={keys} value={item}>{item}</Option>)}
                    </Select>
                </Col>
                <Col data-inspector-line="66" data-inspector-column="16" data-inspector-relative-path="src/common/citySelect/index.com.jsx" span={4}>
                    <Select data-inspector-line="67" data-inspector-column="20" data-inspector-relative-path="src/common/citySelect/index.com.jsx" style={{
          width: '100%',
          paddingRight: 15
        }} defaultValue="请选择" value={areaValue} onChange={value => this.getAreaValue(value)}>
                        {/* <Option value="请选择">请选择区域</Option> */}
                        {areaArr.map((item, keys) => <Option data-inspector-line="70" data-inspector-column="53" data-inspector-relative-path="src/common/citySelect/index.com.jsx" key={keys} value={item}>{item}</Option>)}
                    </Select>
                </Col>
                <Col data-inspector-line="74" data-inspector-column="16" data-inspector-relative-path="src/common/citySelect/index.com.jsx" span={8}>
                {[null, <Input data-inspector-line="76" data-inspector-column="26" data-inspector-relative-path="src/common/citySelect/index.com.jsx" allowClear value={printValue} onChange={e => this.changePrintValue(e.target.value)} />][printIsShow]}
                </Col>
               
            </Row>;
  }
}
CitySelect.defaultProps = {
  printIsShow: 0
};
export default CitySelect;